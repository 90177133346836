import get from 'lodash/get';
import { h, Component } from 'preact';
import { IntlProvider, Localizer, MarkupText, Text } from 'preact-i18n';

import { AmplitudeLabelComponents, Pathways, SuffixOptions, VehicleStatus } from '~lib/enum';

import AutoFiLogo from '~ui/assets/autofi-logo.svg';

import FormHeader from '~ui/components/formHeader';
import isolate from '~ui/components/isolate';
import makePhoneNumberPlaceholder from '~ui/components/makePhoneNumberPlaceholder';
import modal from '~ui/components/modal';
import PrettyInput from '~ui/components/prettyInput';
import PrettyDropdown from '~ui/components/prettyDropdown';
import styles from '~ui/components/explorePayments/explorePaymentsForm.styl';
import SubmitButton from '~ui/components/submitButton';
import withStyles from '~ui/components/withStyles';

import * as utils from '~ui/utils';
import { trackGoogleUniversalAnalyticsEvent, trackShiftDigitalEvent } from '~ui/utils/analytics';
import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';
import consumerSession from '~lib/ui/utils/consumerSession';
import ConsumerMFAClient from '~lib/ui/components/consumerMFAClient';

class ExplorePaymentsForm extends Component {
	state = {
		formData: { rememberMe: true },
		inputValidity: {},
		submitEvent: null,
		touched: false,
	};

	componentDidMount = () => {
		this.initializeInputState();

		if (!this.props.hidden) {
			this.trackGa4LoadEvents();
		}
	};

	trackGa4LoadEvents = () => {
		const { vehicle, autofiData } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event('asc_page_view', { page_type: 'sales', vehicle }, googleEventMetaData);
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { applicant, autofiData, hidden, pageType, vehicle } = this.props;

		const applicantChanged = applicant !== prevProps.applicant;
		const { dealer: currentDealer, sessionData } = autofiData;

		const formBecameVisible = prevProps.hidden && !hidden;

		if (formBecameVisible) {
			this.trackGa4LoadEvents();
		}

		if (formBecameVisible || applicantChanged) {
			this.initializeInputState();
		}

		const { submitEvent, touched } = this.state;
		if (!prevState.touched && touched) {
			trackShiftDigitalEvent('drLeadFormStart', vehicle, currentDealer, sessionData, applicant, pageType);
		}

		const { Pending } = VehicleStatus;
		const prevVehicleStatus = get(prevProps.vehicle, 'status', Pending);
		const vehicleStatus = get(vehicle, 'status', Pending);
		const formWasReadyToSubmit = Boolean(prevVehicleStatus !== Pending && prevState.submitEvent);
		const formIsReadyToSubmit = Boolean(vehicleStatus !== Pending && submitEvent);
		if (!formWasReadyToSubmit && formIsReadyToSubmit) {
			this.submit();
		}
	};

	handleInputChange = (event) => {
		this.setState({ touched: true });
		this.updateInputState(event.target);
	};

	updateInputState = ($input) => {
		const value = $input.type === 'checkbox' ? $input.checked : $input.value;
		const { name, validity } = $input;

		this.setState((prev) => ({
			formData: { ...prev.formData, [name]: value },
			inputValidity: { ...prev.inputValidity, [name]: validity },
		}));
	};

	initializeInputState = () => {
		[...this.$form.querySelectorAll('input, textarea, select')].forEach(this.updateInputState);
	};

	suffixDropdownRef = (dropdown) => (this.suffixDropdown = dropdown);

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ submitEvent: event });
	};

	submit = async () => {
		const {
			autofiData,
			lockedPricingEnabled,
			pageType,
			handleExplorePaymentsSubmit,
			trackAmplitudeEvent,
			vehicle,
			showPage,
			setOnCompleteMFA,
			setApplicant,
		} = this.props;
		const { dealer: currentDealer, sessionData, launchDarklyFeatureFlags, apiProxyUrl } = autofiData;
		const { formData, submitEvent } = this.state;
		const { email, firstName, middleName, lastName, suffix, phone, rememberMe } = formData;
		const contactData = {
			email,
			name: `${firstName.trim()} ${lastName.trim()}`,
			middleInitial: middleName,
			suffix: !suffix || suffix === 'None' ? '' : suffix,
			phone,
			rememberMe,
		};
		const dealer = vehicle.dealer || currentDealer;
		const applicant = utils.makeApplicant({ contactData, dealer });
		const googleEventMetadata = { dealer: currentDealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_submission',
			{ form_name: 'explore_payment', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetadata
		);
		trackGoogleAnalytics4Event(
			'asc_form_submission_sales',
			{ form_name: 'explore_payment', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetadata
		);
		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'explore_payment', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetadata
		);
		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetadata
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetadata
		);

		trackGoogleUniversalAnalyticsEvent('Explore Payment Lead', googleEventMetadata);

		trackShiftDigitalEvent('drLeadFormFinish', vehicle, currentDealer, sessionData, applicant, pageType);

		const { PathwaySubmit } = AmplitudeLabelComponents.Label;
		const pathway = lockedPricingEnabled ? Pathways.UnlockPricing : Pathways.ExplorePayments;
		// TODO: change "action" property below to "label" to make this consistent
		// with other submit events.
		// See https://autofi.atlassian.net/browse/CS-1658
		trackAmplitudeEvent({ action: PathwaySubmit, event: submitEvent, pathway, vehicle });

		let isAuthorized = true;

		if (launchDarklyFeatureFlags?.enableMfaAuthCheck) {
			({ isAuthorized } = await consumerSession.checkAuthStatus({
				apiProxyUrl,
				launchDarklyFeatureFlags,
				sessionData,
				pathway: Pathways.ExplorePayments,
			}));
		}

		if (isAuthorized) {
			handleExplorePaymentsSubmit(applicant, () => this.setState({ submitEvent: null }));
			return;
		}

		setOnCompleteMFA(() => {
			handleExplorePaymentsSubmit(applicant, () => this.setState({ submitEvent: null }));
		});

		setApplicant(applicant, () => showPage(ConsumerMFAClient));
	};

	inputIsValid = (inputName) => {
		// If valid property is undefined, input hasn't been touched, so don't
		// consider it invalid.
		return Boolean(get(this.state.inputValidity, [inputName, 'valid'], true));
	};

	requiredFieldsAreFilled = () => {
		const { email, firstName, lastName, phone } = this.state.formData;
		const { requirePhone } = this.props.autofiData.dealer.websiteSettings.ui.features.getYourPrice;
		return Boolean(firstName && lastName && email && (requirePhone ? phone : true));
	};

	formIsValid = () => {
		const { email, phone } = this.state.formData;
		return (
			this.requiredFieldsAreFilled() &&
			this.inputIsValid('firstName') &&
			this.inputIsValid('lastName') &&
			this.inputIsValid('middleName') &&
			(!phone || this.inputIsValid('phone')) &&
			(!email || this.inputIsValid('email'))
		);
	};

	handleClose = () => {
		const { autofiData, close, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'explore_payment', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);

		close();
	};

	render = (props) => {
		const { applicant, autofiData, dictionary, subTitle, title } = props;
		const { launchDarklyFeatureFlags } = autofiData;
		const enableNameSuffix = launchDarklyFeatureFlags.enableNameSuffix ?? false;
		const { formData, submitEvent } = this.state;
		const { requirePhone } = autofiData.dealer.websiteSettings.ui.features.getYourPrice;

		return (
			<IntlProvider definition={dictionary}>
				<div className="autofi-modal explorePaymentsForm">
					<FormHeader close={this.handleClose} subTitle={subTitle} title={title} />
					<div className="modalContent">
						<form class="autofi-form" ref={($el) => (this.$form = $el)}>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="firstName"
										defaultValue={applicant.name.first}
										placeholder={<Text id="first-name">First Name</Text>}
										required
										pattern={utils.validationPatterns.name}
										hint={
											utils.hasNoNumbers(formData.firstName) ? '' : <Text id="invalid-name-numbers-are-not-allowed" />
										}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="middleName"
										maxlength={1}
										size={1}
										pattern={utils.validationPatterns.middleInitial}
										defaultValue={applicant.name.middle}
										placeholder={<Text id="middle-initial">MI</Text>}
										hint={/(?:[a-zA-Z]|^)$/.test(formData.middleName) ? '' : <Text id="invalid-mi" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="lastName"
										defaultValue={applicant.name.last}
										placeholder={<Text id="last-name">Last Name</Text>}
										required
										pattern={utils.validationPatterns.name}
										hint={
											utils.hasNoNumbers(formData.lastName) ? (
												''
											) : (
												<Text id="invalid-last-name-numbers-are-not-allowed" />
											)
										}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								{enableNameSuffix && (
									<Localizer>
										<PrettyDropdown
											class="suffixField"
											name="suffix"
											onChange={this.handleInputChange}
											options={SuffixOptions}
											placeholder={<Text id="suffix">Suffix</Text>}
											value={applicant.name.suffix ?? SuffixOptions[0].value}
											ref={this.suffixDropdownRef}
										/>
									</Localizer>
								)}
							</div>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="email"
										defaultValue={applicant.email}
										type="email"
										placeholder={<Text id="email">Email</Text>}
										hint={this.inputIsValid('email') ? '' : <Text id="invalid-email-address" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="phone"
										defaultValue={applicant.phone}
										type="tel"
										placeholder={makePhoneNumberPlaceholder({ requirePhone })}
										hint={this.inputIsValid('phone') || !formData.phone ? '' : <Text id="invalid-phone-number" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<SubmitButton
									onClick={this.handleSubmit}
									disabled={!this.formIsValid()}
									submitting={Boolean(submitEvent)}
								>
									<Text id="next-explore-payments" />
								</SubmitButton>
							</div>
							<label className="checkboxContainer">
								<input
									type="checkbox"
									name="rememberMe"
									checked={formData.rememberMe}
									onChange={this.handleInputChange}
								/>
								<div>
									<Text id="remember-me" />
									<div className="explanation">
										<MarkupText id="you-wont-have-to-fill-out" />
									</div>
								</div>
							</label>
							<div class="row">
								<div className="consent">
									<MarkupText id="tcpa-consent" />
								</div>
							</div>
						</form>
						<div class="poweredBy">
							<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
						</div>
					</div>
				</div>
			</IntlProvider>
		);
	};
}

const WrappedExplorePaymentsForm = modal(isolate(withStyles(ExplorePaymentsForm, styles)));

WrappedExplorePaymentsForm.allRequiredFieldsFilled = (dealer, applicant) => {
	return utils.applicantHasContactInfoInSession(applicant, dealer);
};

export default WrappedExplorePaymentsForm;
